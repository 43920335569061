import { ResourceDetail } from '@/src/modules/resources/resources.types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import TooMuchModal from '../components/FdocList/TooMuchModal';
import { getFabricAppExtensionInstalled, sendRequestOpenMultipleTabs } from '../services/extension';
import { isTruthy } from '../utils/guards';

type OpenInTabsOptions = {
  confirmed?: boolean;
  newWindow?: boolean;
};

const useOpenInTabs = () => {
  const [showConfirmation, setShowConfirmation] = useState<number | null>(null);
  const [fabricExtensionInstalled, setFabricExtensionInstalled] = useState<boolean | null>(null);

  const [lastFdocs, setLastFdocs] = useState<ResourceDetail[]>([]);
  const [lastOptions, setLastOptions] = useState<OpenInTabsOptions | undefined>({});

  useEffect(() => {
    getFabricAppExtensionInstalled().then(setFabricExtensionInstalled);
  }, []);

  const openInTabs = useCallback(
    async (resources: ResourceDetail[], options?: OpenInTabsOptions) => {
      if (!fabricExtensionInstalled) return;
      setLastFdocs([]);
      setLastOptions({});
      setShowConfirmation(null);

      const urls = resources.map((resource) => resource.originUrl).filter(isTruthy);

      if (urls.length > 8 && !options?.confirmed) {
        setLastFdocs(resources);
        setShowConfirmation(urls.length);
        setLastOptions(options);
        return;
      }

      if (!urls.length) return;

      try {
        const response = await sendRequestOpenMultipleTabs(urls, options?.newWindow);

        if (!response) throw new Error('No response');
      } catch (e) {
        console.error(e);
      }
    },
    [fabricExtensionInstalled],
  );

  return {
    openInTabs,
    fabricExtensionInstalled,
    confirmationModal: useMemo(
      () => (
        <>
          {showConfirmation && (
            <TooMuchModal
              totalTabs={showConfirmation}
              onContinue={() =>
                openInTabs(lastFdocs, {
                  ...lastOptions,
                  confirmed: true,
                })
              }
              onCancel={() => setShowConfirmation(null)}
            />
          )}
        </>
      ),
      [showConfirmation, lastFdocs, lastOptions, openInTabs],
    ),
  };
};

export default useOpenInTabs;
