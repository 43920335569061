import { useResponsive } from '@/src/hooks/responsive';
import useCopyToClipboard from '@/src/hooks/useCopyToClipboard';
import { useDownloadResource } from '@/src/hooks/useDownloadFdoc';
import { useAskAssistantItem } from '@/src/modules/assistant/hooks/useAskAssistantItem';
import { useQueryInbox } from '@/src/modules/connections/queries/useQueryInbox';
import {
  IconAssistantFace,
  IconCopy,
  IconDownload,
  IconEye,
  IconFolder,
  IconLinkWindow,
  IconMove,
  IconTag,
  IconTrash,
  IconUserPlus,
} from '@/src/modules/icons';
import LabelSelector from '@/src/modules/labels/components/LabelSelector/LabelSelector';
import { ApiColorLabel } from '@/src/modules/labels/labels.types';
import { useQueryMembersByListId } from '@/src/modules/members/queries/useQueryMembersByListId';
import { useMutationDeleteResourcesById } from '@/src/modules/resources/mutations/useMutationDeleteResourcesById';
import { ResourceDetail } from '@/src/modules/resources/resources.types';
import {
  isResourceDetailDocument,
  isResourceDetailFolder,
  isResourceDetailImage,
  isResourceIntegrationItem,
} from '@/src/modules/resources/utils/resourceDetailGuards';
import { Space } from '@/src/modules/spaces/spaces.types';
import { ContextMenu } from '@/src/modules/ui/components/ContextMenu/ContextMenu';
import { DropdownItemIconContainer } from '@/src/modules/ui/components/DropdownMenu/Item';
import { setGlobalSelectionOptions } from '@/src/store/ui';
import { OptimisticDraft } from '@/src/types/draftable';
import { EnterIcon } from '@radix-ui/react-icons';
import { nativeWindow } from '@todesktop/client-core';
import { publish } from '@todesktop/client-ipc';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

type ResourcePreviewContextMenuProps = {
  children: React.ReactElement;
  onItemClickAction?: (e?: React.MouseEvent) => void;
  onGoToSourceClick: (e?: React.MouseEvent) => void;
  hideDelete?: boolean;
  overrideOnDelete?: (e?: React.MouseEvent) => void;
  hideMove?: boolean;
  sourceUrl?: string | null;
  setShowingShareModal?: (showing: boolean) => void;
  style?: React.CSSProperties;
  openTagModal?: (e: React.MouseEvent) => void;

  resource: OptimisticDraft<ResourceDetail>;
  colorLabels?: ApiColorLabel[];
  list?: Space;
  contextDisabled?: boolean;
};

export const ResourcePreviewContextMenu: React.FC<ResourcePreviewContextMenuProps> = ({
  contextDisabled,
  onItemClickAction,
  onGoToSourceClick,
  hideDelete = false,
  overrideOnDelete,
  hideMove = false,
  setShowingShareModal,
  sourceUrl,
  colorLabels,
  list,
  openTagModal,
  children,
  resource,
}) => {
  const [, copy] = useCopyToClipboard();
  const { inboxRoot } = useQueryInbox();
  const router = useRouter();
  const askAssistantItem = useAskAssistantItem();

  const isResourceDownloadable = !!resource.fileUrl;
  const isIntegrationItem = isResourceIntegrationItem(resource);

  const { isCurrentUserMember } = useQueryMembersByListId(list?.id);

  /**
   * readonly when on specific list as a visitor
   */
  const readOnly = (list?.id && !isCurrentUserMember) || false;

  const { isMobileView } = useResponsive();

  const handleCopySourceUrl = () => {
    if (!sourceUrl) return;

    copy(sourceUrl);
  };

  const handleAddToSpace = () => {
    if (!resource.id) return;

    if (router.pathname === '/desktop-global-search') {
      try {
        publish('add-to-space', { resource });
      } catch (e) {
        window.open(`fabric://add-to-space?id=${resource.id}`, '_blank');
      }
      nativeWindow.close();
      return;
    }

    setGlobalSelectionOptions({
      selectedFdocsIds: [resource.id],
    });
  };

  const handleDownload = useDownloadResource(resource);

  const parentId = resource.parent?.id;
  const openEnclosingSpace = parentId
    ? () => {
        let path = `/folders/${parentId}`;

        if (resource.root?.folder.id && resource.root?.folder.id === parentId) {
          path = `/spaces/${parentId}`;
        }
        if (inboxRoot?.id === parentId) {
          path = '/saved';
        }

        if (router.pathname === '/desktop-global-search') {
          try {
            publish('open-path', { path: path });
          } catch (e) {
            window.open(`fabric://open-path?path=${path}`, '_blank');
          }
          nativeWindow.close();
          return;
        }

        router.push(path, undefined, { shallow: true });
      }
    : undefined;

  const downloadText = React.useMemo(() => {
    if (isResourceDetailImage(resource)) {
      return 'Download image';
    }

    if (isResourceDetailDocument(resource) && resource.mimeType?.startsWith('application/pdf')) {
      return 'Download PDF';
    }

    return 'Download';
  }, [resource]);

  const mutationDeleteResources = useMutationDeleteResourcesById();
  const onDelete = () => mutationDeleteResources.confirmAndMutateAsync([resource.id]);

  const askAssistantItemHandler = () => {
    askAssistantItem(resource.id);
  };

  /**
   * for color label selector
   */
  const resources = useMemo(
    () =>
      resource
        ? [
            {
              id: resource.id,
              label: resource.label
                ? {
                    id: resource.label.id,
                  }
                : null,
            },
          ]
        : [],
    [resource],
  );

  return (
    <ContextMenu
      contextDisabled={contextDisabled}
      previewAnchor={isMobileView}
      content={
        <>
          <ContextMenu.Group>
            {onItemClickAction && (
              <ContextMenu.Item
                onClick={onItemClickAction}
                data-testid="item-context-menu-action-button"
              >
                <DropdownItemIconContainer>
                  <IconEye />
                </DropdownItemIconContainer>
                {resource.kind === 'folder' ? 'Open' : 'View'}

                {!isMobileView && (
                  <kbd
                    style={{
                      padding: '4px',
                    }}
                  >
                    <span>space</span>
                  </kbd>
                )}
              </ContextMenu.Item>
            )}
            {isResourceDownloadable && (
              <ContextMenu.Item
                onClick={handleDownload}
                data-testid="item-context-menu-download-button"
              >
                <DropdownItemIconContainer>
                  <IconDownload />
                </DropdownItemIconContainer>
                {downloadText}
              </ContextMenu.Item>
            )}
            {sourceUrl && (
              <>
                <ContextMenu.Item
                  onClick={onGoToSourceClick}
                  data-testid="item-context-menu-go-to-source-button"
                >
                  <DropdownItemIconContainer>
                    <IconLinkWindow />
                  </DropdownItemIconContainer>
                  Go to original URL{' '}
                  {!isMobileView && (
                    <kbd>
                      <EnterIcon />
                    </kbd>
                  )}
                </ContextMenu.Item>
                <ContextMenu.Item
                  onClick={handleCopySourceUrl}
                  data-testid="item-context-menu-copy-source-button"
                >
                  <DropdownItemIconContainer>
                    <IconCopy />
                  </DropdownItemIconContainer>
                  Copy original URL
                </ContextMenu.Item>
              </>
            )}
            {!readOnly && !hideMove && !isIntegrationItem && (
              <ContextMenu.Item
                onClick={handleAddToSpace}
                data-testid="item-context-menu-add-to-space"
              >
                <DropdownItemIconContainer>
                  <IconMove />
                </DropdownItemIconContainer>
                Move...
              </ContextMenu.Item>
            )}
            {!readOnly && colorLabels && list && !isMobileView && (
              <ContextMenu.ItemRaw>
                <LabelSelector space={list} resources={resources} colorLabels={colorLabels} />
              </ContextMenu.ItemRaw>
            )}
            {((router.pathname !== '/spaces/[listId]' &&
              router.pathname !== '/saved' &&
              router.pathname !== '/folders/[folderId]') ||
              typeof router.query.search === 'string') && (
              <ContextMenu.Item
                onClick={openEnclosingSpace}
                data-testid="item-context-menu-go-to-list-button"
              >
                <DropdownItemIconContainer>
                  <IconFolder />
                </DropdownItemIconContainer>
                Open enclosing space
              </ContextMenu.Item>
            )}
            {!readOnly && (
              <ContextMenu.Item
                onClick={openTagModal}
                data-testid="item-context-menu-edit-tags-button"
              >
                <DropdownItemIconContainer>
                  <IconTag />
                </DropdownItemIconContainer>
                Add/edit tags
              </ContextMenu.Item>
            )}
          </ContextMenu.Group>

          <ContextMenu.Group>
            <ContextMenu.Item
              data-testid="item-context-menu-ask-about-item"
              onClick={askAssistantItemHandler}
            >
              <DropdownItemIconContainer>
                <IconAssistantFace />
              </DropdownItemIconContainer>
              Ask about this item...
            </ContextMenu.Item>
          </ContextMenu.Group>

          {!readOnly && setShowingShareModal && !isResourceDetailFolder(resource) && (
            <ContextMenu.Group>
              <ContextMenu.Item
                onClick={() => setShowingShareModal(true)}
                data-testid="item-context-menu-share-button"
              >
                <DropdownItemIconContainer>
                  <IconUserPlus />
                </DropdownItemIconContainer>
                {resource.isDirectShared ? 'Manage sharing' : 'Share'}
              </ContextMenu.Item>
            </ContextMenu.Group>
          )}

          {!readOnly && !isIntegrationItem && !hideDelete && (
            <ContextMenu.Group>
              <ContextMenu.Item
                onClick={overrideOnDelete ?? onDelete}
                data-testid="item-context-menu-delete-button"
                variant="danger"
              >
                <DropdownItemIconContainer>
                  <IconTrash />
                </DropdownItemIconContainer>
                Delete {isResourceDetailFolder(resource) && 'folder'}
              </ContextMenu.Item>
            </ContextMenu.Group>
          )}
        </>
      }
    >
      {children}
    </ContextMenu>
  );
};
