import { ModalDestinationSelector } from '@/src/modules/resources/components/DestinationSelector/ModalDestinationSelector';
import { useMutationMoveResourcesById } from '@/src/modules/resources/mutations/useMutationMoveResourcesById';
import { ResourceDetail } from '@/src/modules/resources/resources.types';
import { OptimisticDraft } from '@/src/types/draftable';
import React from 'react';

export const ModalMoveResources: React.FC<{
  resources: OptimisticDraft<ResourceDetail>[];
  onClose: VoidFunction;
  onMoveSuccess: VoidFunction;
  open: boolean;
}> = ({ resources, onClose, onMoveSuccess, open }) => {
  const mutationMoveResourcesById = useMutationMoveResourcesById();

  const resourcesToMoveIds = React.useMemo(() => resources.map((f) => f.id), [resources]);
  /**
   * search
   */

  return (
    <ModalDestinationSelector
      open={open}
      onClose={onClose}
      title="Move to..."
      resources={resources}
      getIsNodeSelectable={(node) => {
        if (node.isRootIntegration) {
          return false;
        }

        if (resourcesToMoveIds.includes(node.id)) {
          return false;
        }

        return true;
      }}
      ctaButtonProps={{
        children: 'Move',
        ['data-testid']: 'move-items-modal-move-button',
      }}
      onDestinationSelect={(destination) => {
        mutationMoveResourcesById.mutate(
          {
            resourceIds: resourcesToMoveIds,
            newParent: {
              id: destination.id,
              name: destination.title,
            },
          },
          {
            onSuccess: () => {
              onMoveSuccess();
            },
          },
        );
      }}
    />
  );
};
