import React, { useMemo } from 'react';

import CheckIcon from '@/public/images/icons/Check.svg';
import PlusIcon from '@/public/images/icons/Plus.svg';
import { ApiColorLabel } from '@/src/modules/labels/labels.types';
import { useMutationChangeResourceLabel } from '@/src/modules/resource-detail/mutations/useMutationChangeResourceLabel';
import { Space } from '@/src/modules/spaces/spaces.types';
import { getTextColor, sortColorLabels } from '@/src/utils/color';
import clsx from 'clsx';
import styles from './LabelSelector.module.scss';

const LabelSelector: React.FC<{
  resources: { id: string; label: { id: number } | null }[];
  colorLabels: ApiColorLabel[];
  space: Space;
}> = ({ resources, space, colorLabels }) => {
  const sortedColorLabels = useMemo(() => sortColorLabels(colorLabels), [colorLabels]);

  const [hoveredLabel, setHoveredLabel] = React.useState<ApiColorLabel | null>(null);

  const mutationChangeResourceLabel = useMutationChangeResourceLabel();

  const handleLabelClick = async (colorLabel: ApiColorLabel) => {
    if (resources.length < 1 || !space) return;

    try {
      const nextLabel = resources.every((resource) => resource.label?.id === colorLabel.id)
        ? null
        : colorLabel;

      resources.forEach((fdoc) => {
        mutationChangeResourceLabel.mutate({
          resourceId: fdoc.id,
          spaceId: space.id,
          label: nextLabel,
        });
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={styles.labelSelector}>
      {sortedColorLabels.map((colorLabel) => {
        const selected =
          resources.find((f) => f.label?.id === colorLabel.id) &&
          (resources.length < 2 || (resources.length > 1 && hoveredLabel !== colorLabel));
        return (
          <button
            className={clsx(
              styles.bubbleButton,
              selected && styles.selected,
              resources.length > 1 &&
                hoveredLabel &&
                selected &&
                hoveredLabel !== colorLabel &&
                styles.otherHover,
            )}
            style={{
              color: getTextColor(colorLabel.hexColor),
            }}
            key={colorLabel.id}
            onClick={() => handleLabelClick(colorLabel)}
            onMouseEnter={() => setHoveredLabel(colorLabel)}
            onMouseLeave={() => setHoveredLabel(null)}
          >
            <span style={{ backgroundColor: colorLabel.hexColor }} />

            <PlusIcon className={styles.plusIcon} />
            <CheckIcon className={styles.checkIcon} />
          </button>
        );
      })}
    </div>
  );
};

export default LabelSelector;
