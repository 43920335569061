import EditTagsModal, { EditTagsProps } from '@/src/components/Tags/EditTagsModal';
import React, { useMemo } from 'react';
import { useMutationResourcesTagAssign } from '../mutations/useMutationResourcesTagAssign';
import { useQueryMultipleResourceTags } from '../queries/useQueryResourceTags';
import { getSelectedAndSemiselectedTagIds } from '../utils';
import { ModalControls } from '../../ui/components/Modal';
import { MagicSuggestionsContextProvider } from '../../magic/components/MagicSuggestionsContext';

export const EditTagsPerMultipleResourcesModal: React.FC<{
  resourceIds: string[];
  modalControls: ModalControls;
}> = ({ resourceIds, modalControls }) => {
  const mutationResourcesTagAssign = useMutationResourcesTagAssign();

  const queryResourcesTags = useQueryMultipleResourceTags(resourceIds, {
    enabled: modalControls.open,
  });

  const { selectedTags, indeterminateTags } = React.useMemo(
    () => getSelectedAndSemiselectedTagIds(queryResourcesTags.data),
    [queryResourcesTags.data],
  );

  const selectHandler: EditTagsProps['onSelect'] = (tag, selected) => {
    mutationResourcesTagAssign.mutate({
      tag,
      resourceIds,
      operation: selected ? 'assign' : 'unassign',
    });
  };

  const suggestionsContext = useMemo(
    () => (modalControls.open ? { resourceIds } : undefined),
    [modalControls.open, resourceIds],
  );

  return (
    <MagicSuggestionsContextProvider
      context={suggestionsContext}
      keepPrevious={false}
      includeFolders={false}
    >
      <EditTagsModal
        {...modalControls}
        selectedTags={selectedTags}
        indeterminateTags={indeterminateTags}
        onSelect={selectHandler}
      />
    </MagicSuggestionsContextProvider>
  );
};
