import FolderIcon from '@/public/images/icons/Folder.png';
import { useResponsive } from '@/src/hooks/responsive';
import { useDomContentRect } from '@/src/hooks/useDomRect';
import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { useQueryInbox } from '@/src/modules/connections/queries/useQueryInbox';
import { useQueryResourceRootIntegration } from '@/src/modules/connections/queries/useQueryResourceRootIntegration';
import { ApiColorLabel } from '@/src/modules/labels/labels.types';
import { useMutationRenameColorLabel } from '@/src/modules/labels/mutations/useMutationRenameColorLabel';

import RecapSummaryButton from '@/src/modules/recap/components/RecapSummaryButton';
import { useMutationChangeResourceLabel } from '@/src/modules/resource-detail/mutations/useMutationChangeResourceLabel';
import { useResourceClickHandler } from '@/src/modules/resources/components/ResourcePreview/hooks/useResourceClickHandler';
import { ResourcePreviewProps } from '@/src/modules/resources/components/ResourcePreview/ResourcePreview.types';
import ResourcePreviewEnhanced from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewEnhanced';
import { ResourcePreviewSkeleton } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewSkeleton/ResourcePreviewSkeleton';
import { useQueryFolder } from '@/src/modules/resources/queries/useQueryFolder';
import { ResourceDetail, ResourceDetailFolder } from '@/src/modules/resources/resources.types';
import { Space } from '@/src/modules/spaces/spaces.types';
import { useIntersectionObserver } from '@/src/modules/ui/components/IntersectionObserver/useIntersectionObserver';
import { useWoody } from '@/src/services/woody/woody';
import { OptimisticDraft } from '@/src/types/draftable';
import { ObjectDragEvent, ObjectDragOver } from '@/src/types/draggable';
import DashboardButton from '@/src/ui/DashboardButton/DashboardButton';
import { newArrayLength } from '@/src/utils/array';
import { nativeWindow } from '@todesktop/client-core';
import { publish } from '@todesktop/client-ipc';
import clsx from 'clsx';
import { LayoutGroup } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, {
  CSSProperties,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { shallow } from 'zustand/shallow';
import { OfViewMode } from '../ViewModeSwitcher/ViewModeSwitcher';
import { SplitResourceGroup } from './FdocList';
import styles from './FdocListDivision.module.scss';

type DragOverElement = {
  position: number;
  resourceId: string;
  width: number;
  height: number;
};

const FdocListDivision: React.FC<{
  optimize?: boolean;
  viewMode: OfViewMode;
  colorLabel?: ApiColorLabel | null;
  resources: OptimisticDraft<ResourceDetail>[];
  titleProp?: string;
  disableItemPreviewContextMenu?: boolean;

  resourcesTotalCount?: number;

  zoomLevel?: number;

  colorLabels?: ApiColorLabel[];
  splitResourceGroup?: SplitResourceGroup;
  list?: Space;
  resourceId?: string;
  style?: CSSProperties;
  rootStyle?: CSSProperties;
  root?: HTMLElement;
  slimStyle?: boolean;

  columns: number;
  gap?: CSSProperties['gap'];

  previewSelectedFdocs: string[];
  canMultiSelect?: boolean;
  canDelete?: boolean;
  canMove?: boolean;
  hasSeparateDivisions?: boolean;
  resourcesLoadedCount?: number;
  amountOfDivisions?: number;
  index?: number;

  rootList?: HTMLElement | null;
  anchor?: React.ReactNode;
  /**
   * new card preview props
   */
  resourcePreviewProps?: Omit<ResourcePreviewProps, 'resource'>;
  layoutId: string;
  showLoadingSkeletons: boolean;

  onClickFolder?: (resourceFolder: ResourceDetailFolder) => void;
  onClickResource?: (resource: ResourceDetail) => void;
}> = ({
  optimize = false,
  viewMode,
  zoomLevel,
  colorLabel,
  resources,
  titleProp,
  style,
  rootStyle,
  slimStyle,
  gap = '32px',
  splitResourceGroup,
  resourcesTotalCount,
  hasSeparateDivisions,
  resourcesLoadedCount,
  amountOfDivisions,
  index,
  colorLabels,
  list,
  rootList,
  columns,
  disableItemPreviewContextMenu,
  resourceId,
  previewSelectedFdocs,
  canMultiSelect,
  canDelete,
  canMove = true,
  anchor,
  resourcePreviewProps,
  layoutId,
  showLoadingSkeletons: showLoadingSkeletonsProp,
  onClickFolder,
  onClickResource,
}) => {
  const { client } = useWoody();

  const [divisionRootEl, setDivisionRootEl] = useState<HTMLDivElement | null>(null);
  const [rootDom] = useDomContentRect(divisionRootEl);

  const inView = useIntersectionObserver({
    defaultInView: true,
    target: divisionRootEl,
    enabled: optimize,
    rootEl: rootList,
    rootMargin: `${2500 / (zoomLevel ?? 1)}px`,
    threshold: 0.001,
    recalculateKey: `${resources.length}.${splitResourceGroup?.id}`,
  });

  const { folder } = useQueryFolder(resourceId, {
    enabled: isQueryEnabled([!!resourceId, !!inView, !titleProp]),
  });

  const title = titleProp || folder?.name || '';

  const router = useRouter();
  const listId =
    splitResourceGroup?.space?.id ??
    (typeof router.query.listId === 'string' ? router.query.listId : undefined);

  const { integrationRoot } = useQueryResourceRootIntegration({
    fn: (resourceRoot) =>
      resourceRoot.integration.id === splitResourceGroup?.space?.id ||
      resourceRoot.id === splitResourceGroup?.resourceId,
  });

  const integration = integrationRoot?.integration;

  const [listRef, setListRef] = useState<HTMLDivElement | null>(null);
  const [dragOverElement, setDragOverElement] = useState<DragOverElement | null>(null);

  const [deferredDragOverElement, setDeferredDragOverElement] = useState<DragOverElement | null>(
    null,
  );

  useEffect(() => {
    setDeferredDragOverElement(dragOverElement);
  }, [dragOverElement]);

  const { isMobileView } = useResponsive();

  const handleClickResource = useResourceClickHandler({
    onClickResource,
    onClickFolder,
  });

  /**
   * Fdoc item new vs old
   */

  const resourceMap = (resource: OptimisticDraft<ResourceDetail>) => (
    <ResourcePreviewEnhanced
      key={resource.draftId || resource.id}
      viewMode={viewMode}
      resource={resource}
      selected={previewSelectedFdocs.includes(resource?.id ?? '')}
      disableContextMenu={disableItemPreviewContextMenu}
      canMultiSelect={canMultiSelect}
      canDelete={canDelete}
      canMove={canMove}
      /** do not show labels when in sort mode */
      colorLabels={viewMode === 'Sort' ? undefined : colorLabels}
      list={list}
      isSelectable={true}
      resourcePreviewProps={resourcePreviewProps}
      zoomLevel={zoomLevel}
      onClick={handleClickResource}
    />
  );

  useEffect(() => {
    if (!listRef) return;

    const onDragOver = (e: CustomEvent<ObjectDragOver>) => {
      const { objectId: resourceId, height, width, y } = e.detail;

      // get child elements of the list between
      const children = Array.from(listRef.children).filter(
        (child) => child instanceof HTMLElement && !child.dataset.droppableCursor,
      ) as HTMLElement[];

      // if there are no children, just return
      if (children.length < 1) {
        setDragOverElement({
          position: 10,
          resourceId,
          height,
          width,
        });
        return;
      }

      // get closest index to place the fake element using the accurateY position
      // and ignoring the element that contains a data-draggable-id, and if found
      // detract an offset on the next elements to make space for the fake element
      const calculated = children.reduce(
        (acc, child, index) => {
          if (acc.index !== children.length) return acc;

          const childTop = child.getBoundingClientRect().y;
          const childBottom = childTop + child.getBoundingClientRect().height;
          const childCenter = childTop + child.getBoundingClientRect().height / 2;

          if (y < childCenter) {
            const listTop = listRef.getBoundingClientRect().y;

            const newCenter =
              index === 0
                ? listTop + (childTop - listTop) / 2
                : acc.bottom + (childTop - acc.bottom) / 2;

            return {
              ...acc,
              top: childTop,
              bottom: childBottom,
              center: newCenter,
              index: index - 1,
            };
          } else {
            return {
              ...acc,
              bottom: childBottom,
            };
          }
        },
        { index: children.length, top: 0, bottom: 0, center: 0 },
      );

      const center = calculated.center - listRef.getBoundingClientRect().y + listRef.scrollTop;
      const bottom = calculated.bottom - listRef.getBoundingClientRect().y + listRef.scrollTop;

      setDragOverElement({
        position: center < 0 ? bottom + 10 : center,
        resourceId,
        width,
        height,
      });
    };

    const onDragOut = () => {
      setDragOverElement(null);
    };

    listRef.addEventListener('object-drag-over', onDragOver);
    listRef.addEventListener('object-drag-out', onDragOut);

    return () => {
      listRef.removeEventListener('object-drag-over', onDragOver);
      listRef.removeEventListener('object-drag-out', onDragOut);
    };
  }, [listRef]);

  const { mutate: mutateChangeResourceLabel } = useMutationChangeResourceLabel();

  useEffect(() => {
    if (!listRef || viewMode !== 'Sort' || !list) return;

    const onDragDrop = async (e: CustomEvent<ObjectDragEvent>) => {
      const { objectId: resourceId } = e.detail;

      setDragOverElement(null);

      // If the colorlabel is null it means we want to remove the label
      if (colorLabel === undefined || !splitResourceGroup?.space?.id) return;

      mutateChangeResourceLabel(
        {
          resourceId: resourceId,
          spaceId: splitResourceGroup.space.id,
          label: colorLabel,
        },
        {
          onSettled: () => {
            setDragOverElement(null);
          },
        },
      );
    };

    listRef.addEventListener('object-drag-drop', onDragDrop);

    return () => listRef.removeEventListener('object-drag-drop', onDragDrop);
  }, [
    client,
    colorLabel,
    list,
    listRef,
    viewMode,
    mutateChangeResourceLabel,
    splitResourceGroup?.space?.id,
  ]);

  const isDesktopGlobalSearch = router.pathname === '/desktop-global-search';
  const { inboxRoot } = useQueryInbox();

  const sourceURL = useMemo(() => {
    if (!splitResourceGroup?.resourceId) return null;

    if (splitResourceGroup?.resourceId === inboxRoot?.id) return '/saved';
    else if (splitResourceGroup?.space?.id === splitResourceGroup.resourceId)
      return `/spaces/${splitResourceGroup?.space.id}`;
    else return `/folders/${splitResourceGroup?.resourceId}`;
  }, [splitResourceGroup?.resourceId, splitResourceGroup?.space, inboxRoot?.id]);

  const onClickOpenSource = useCallback(() => {
    if (router.pathname !== '/desktop-global-search') return;

    try {
      publish('open-path', { path: sourceURL });
    } catch (e) {
      window.open(`fabric://open-path?path=${sourceURL}`);
    }
    nativeWindow.close();
  }, [router.pathname, sourceURL]);

  const skeletonComponentsArray = useMemo(() => {
    if (resourcesTotalCount) {
      const calculatedValue = Math.max(
        resourcesTotalCount - (resourcesLoadedCount ?? resources.length),
        0,
      );
      const cappedValue = Math.min(calculatedValue, 20);
      return newArrayLength(cappedValue);
    }
    return [];
  }, [resourcesTotalCount, resourcesLoadedCount, resources.length]);

  const [dynamicTitle, setDynamicTitle] = useState(title ?? '');
  const debounceRef = useRef<number | null>(null);

  const { mutate: mutateRenameColorLabel } = useMutationRenameColorLabel();

  const renameColorLabel = useCallback(() => {
    if (!colorLabel || !listId) return;

    mutateRenameColorLabel({
      folderId: listId,
      colorLabelId: colorLabel?.id ?? 0,
      newTitle: dynamicTitle,
    });
  }, [listId, colorLabel, dynamicTitle, mutateRenameColorLabel]);

  useEffect(() => {
    if (!listId || colorLabel?.name === dynamicTitle) return;

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = window.setTimeout(() => {
      renameColorLabel();
    }, 500);
  }, [listId, colorLabel, dynamicTitle, renameColorLabel]);

  const triggerColorUpdate = () => {
    if (!listId || colorLabel?.name === dynamicTitle) return;

    renameColorLabel();
  };

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDynamicTitle(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      triggerColorUpdate();
    }
  };
  const showDivisionSkeletons = useMemo(() => {
    return (
      hasSeparateDivisions &&
      typeof amountOfDivisions === 'number' &&
      typeof resourcesTotalCount === 'number' &&
      typeof resourcesLoadedCount === 'number' &&
      index === amountOfDivisions - 1 &&
      resourcesLoadedCount < resourcesTotalCount
    );
  }, [hasSeparateDivisions, amountOfDivisions, resourcesTotalCount, resourcesLoadedCount, index]);

  const estimatedContentHeight = useMemo(() => {
    // On mobile view the width/height of the items is dynamic, as such for now we will just return 0
    if (!rootList || !resources.length || isMobileView) return 0;

    if (viewMode === 'List') return resources.length * 72;
    if (viewMode === 'Grid') {
      if (!rootDom) return 0;
      const zoom = isMobileView ? 1 : zoomLevel ?? 1;

      const horizontalGap =
        (typeof gap === 'string' ? parseInt(gap.split(' ').pop() ?? '0') : gap ?? 0) * zoom;
      const verticalGap =
        (typeof gap === 'string' ? parseInt(gap.split(' ').shift() ?? '0') : gap ?? 0) * zoom;

      const columns = Math.floor(rootDom.width / (260 * zoom + horizontalGap / 2));
      const rows = Math.ceil(resources.length / columns);
      return rows * (280 * zoom) + (rows - 1) * verticalGap;
    }
  }, [viewMode, rootDom, resources.length, isMobileView, gap, rootList, zoomLevel]);

  if (resources.length === 0 && viewMode !== 'Sort') return null;

  const showLoadingSkeletons =
    (!hasSeparateDivisions || showDivisionSkeletons) &&
    viewMode !== 'Sort' &&
    showLoadingSkeletonsProp;

  return (
    <div
      ref={setDivisionRootEl}
      className={clsx(
        styles.division,
        viewMode === 'Sort' && styles['division--sort'],
        !!splitResourceGroup?.space && viewMode !== 'Sort' && styles.collection,
        slimStyle && styles.slim,
      )}
      style={rootStyle}
      data-estimated-content-height={estimatedContentHeight}
      key={viewMode}
    >
      {(colorLabel || title) && (
        <div className={styles.header}>
          {colorLabel && (
            <div className={styles.colorBubble} style={{ background: colorLabel.hexColor }} />
          )}
          {!!splitResourceGroup?.space && viewMode !== 'Sort' && !integration && (
            <div className={styles.header_icon}>
              <img src={FolderIcon.src} alt="Folder" />
            </div>
          )}

          {integration && (
            <div className={styles.header_icon}>
              <integration.viewConfig.Icon />
            </div>
          )}
          {title && (
            <h3 className={styles.title}>
              <span>
                {integration && `${integration.viewConfig.integrationName} - `}
                {!colorLabel && title}
              </span>
              {viewMode === 'Sort' && <span className={styles.total}>({resources.length})</span>}

              {colorLabel && (
                <input
                  type="text"
                  value={dynamicTitle}
                  disabled={!colorLabel}
                  style={!colorLabel ? { backgroundColor: '#efefef' } : {}}
                  placeholder="Title"
                  id={colorLabel.id.toString()}
                  onChange={handleChangeTitle}
                  onKeyDown={handleKeyPress}
                  onBlur={triggerColorUpdate}
                />
              )}

              {splitResourceGroup?.recapDate &&
                splitResourceGroup.recapFrequency &&
                splitResourceGroup.recapField && (
                  <RecapSummaryButton
                    date={splitResourceGroup.recapDate}
                    frequency={splitResourceGroup.recapFrequency}
                    field={splitResourceGroup.recapField}
                  />
                )}
            </h3>
          )}

          {title && (sourceURL || router.pathname === '/desktop-global-search') && (
            <DashboardButton
              color="transparent"
              className={styles.openSource}
              style={{
                position: 'relative',
              }}
              onClick={onClickOpenSource}
            >
              Open source location
              {sourceURL && !isDesktopGlobalSearch && (
                <Link
                  href={sourceURL}
                  target={sourceURL.startsWith('fabric://') ? '_blank' : '_self'}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              )}
            </DashboardButton>
          )}
        </div>
      )}
      {inView || !estimatedContentHeight || !optimize ? (
        <div
          className={clsx(
            styles.fdocList,
            viewMode === 'Grid' && styles.gridMode,
            viewMode === 'List' && styles.listMode,
            viewMode === 'Sort' && `${styles.sortMode}`,
            columns === 1 && styles.singleColumn,
            {
              [styles.gridModeV2]: viewMode === 'Grid',
            },
          )}
          ref={setListRef}
          data-droppable
          style={{
            ...style,
            gap: viewMode === 'Grid' ? gap : undefined,
          }}
        >
          {viewMode === 'Sort' && deferredDragOverElement && (
            <div
              key={`dragOverElement-${deferredDragOverElement.resourceId}`}
              data-droppable-cursor
              className={styles.dragOverElement}
              style={{
                transform: `translateY(${deferredDragOverElement.position}px)`,
              }}
            />
          )}
          <LayoutGroup id={`${layoutId}--${viewMode}`}>{resources.map(resourceMap)}</LayoutGroup>

          {/** display the anchor conditionally */}
          {skeletonComponentsArray.length > 0 ? (
            <>
              {/**
               * we know that if there is non empty array of skeletons, we should render the anchor
               * if we actually render the skeletons, we render the anchor inside the first skeleton
               * othwerwise we render the anchor outside without them
               * */}
              {showLoadingSkeletons
                ? skeletonComponentsArray.map((item) => (
                    <ResourcePreviewSkeleton viewMode={viewMode} key={item}>
                      {item === 0 && anchor}
                    </ResourcePreviewSkeleton>
                  ))
                : anchor}
            </>
          ) : (
            resourcesTotalCount === undefined && anchor
          )}
        </div>
      ) : (
        <div
          style={{
            height: estimatedContentHeight,
            flexShrink: 0,
            minHeight: estimatedContentHeight,
          }}
        ></div>
      )}
    </div>
  );
};

export default memo(FdocListDivision, shallow);
